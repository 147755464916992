import logo from '../../resources/plextv-tile.svg'
import './App.css';
import { useEffect, useState } from 'react';
import fetchGilfoyleStatus from '../../data/fetchGilfoyleStatus';


const App = (): JSX.Element => {
  const [gilfoyleStatus, setStatus] = useState<number>();

  useEffect(() => {
      (async() => {
          const res = await fetchGilfoyleStatus();
          setStatus(res);
          console.log("response code: " + gilfoyleStatus)
      })();
  }, []);


  return (
    <main>
     <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
           Gilfoyle is {gilfoyleStatus == 200 ? "up" : "down" }
        </p>
        <p>
          No planned maintenance
        </p>
      </header>
    </div>
    </main>
  );


}

export default App;
